<template>
    <div class="pay-box">
        <div class="pay-content">
            <div class="cart-title">
                <div class="title">
                    支付订单
                </div>
                <div class="warp-box">
                    <ul>
                        <li>
                            <div class="img">
                                <img src="../assets/img/cart-showIcon.png" alt="">
                            </div>
                            <div class="text">购物车</div>
                        </li>
                        <li>
                            <div class="img2">
                                <img src="../assets/img/cart-confirmShowIcon.png" alt="">
                            </div>
                            <div class="text">确认订单</div>
                        </li>
                        <li>
                            <div class="img3">
                                <img src="../assets/img/cart-paymenShowtIcon.png" alt="">
                            </div>
                            <div class="text">支付订单</div>
                        </li>
                        <li>
                            <div class="img4">
                                <img src="../assets/img/cart-successIcon.png" alt="">
                            </div>
                            <div class="text">完成</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="warp-content">
                <div class="container">
                    <div class="order-wrap">
                        <div class="item-order">
                            <div class="order-info">
                                <h2>订单提交成功！</h2>
                                <p class="text-time">请在<span>{{min}}分{{sec}}秒内</span>完成支付, 超时后将取消订单</p>
                                <p>我们将在您完成支付后的72小时内开通课程</p>
                            </div>
                            <!-- <div class="order-total">
                                <p>应付总额：<span>5000</span>元</p>
                                <p>订单详情<em class="icon-down" :class="{'up':showDetail}" @click="showDetail=!showDetail"></em></p>
                            </div> -->
                        </div>
                    </div>

                    <div class="item-pay">
                        <div class="pay-way">
                            <h3>请选择支付方式</h3>
                            <div class="pay pay-ali" :class="{'checked':payType===0}" @click="paySubmit(0)"></div>
                            <div class="pay pay-wechat" :class="{'checked':payType===1}" @click="paySubmit(1)"></div>
                        </div>
                        <div class="pay-imgBox">
                            <div v-show="payType===0">
                                <div class="pay-Img">
                                    <img :src="payImg" alt="">
                                </div>
                                <p class="pay-text">请扫码完成支付，支付金额：<span class="pay-color">{{price}}元</span></p>
                            </div>
                            <div v-show="payType===1">
                                <div class="pay-Img">
                                    <img :src="payImg" alt="">
                                </div>
                                <p class="pay-text">请扫码完成支付，支付金额：<span class="pay-color">{{price}}元</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
    name: 'orderPay',
    data() {
        return {
            showDetail:false,//是否显示订单详情
            payType: 1,//支付类型
            min: 0,
            sec: 0,
            price: JSON.parse(sessionStorage.getItem('totalPrice')),
            orderId: this.$route.query.orderId,
            payImg: '',
            T: '',
            timer: '',
            vaildTime: '',
            remainder: 0
        }
    },
    mounted () {
        this.getTime();
        // this.countdown();
        this.paySubmit(1);
    },
    watch: {
        seconds: {
            handler(newVal) {
                this.num(newVal)
            }
        },
        minute: {
            handler(newVal) {
                this.num(newVal)
            }
        }
    },
    computed: {
        seconds: function() {
            return this.num(this.sec)
        },
        minute: function() {
            return this.num(this.min)
        }
    },
    // destroyed () {
    //     this.stopInit();
    //     this.stopTime();
    // },
    metaInfo: {
        title: '订单付款-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        num(n) {
            return n < 10 ? '0' + n : '' + n;
        },
        // 停止定时器
        stopTime() {
            clearInterval(this.timer);
            sessionStorage.removeItem('validTime');
        },
        stopInit(){
            clearInterval(this.T);
        },
        getTime() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/order/get_remainder_time.html',
                data: {
                    orderId: this.orderId,
                }
            }).then((res) => {
                this.vaildTime = res.data.result.remainderTime;
                this.countdown()
                // sessionStorage.setItem('validTime',JSON.stringify(validTime));
            })
        },
        //倒计时
        countdown(){
            let that = this;
            let validTime = this.vaildTime;
            let timer = setInterval(function(){
                if (validTime > 0) {
                let minutes = Math.floor(validTime / 60);
                let seconds = Math.floor(validTime % 60);
                if(minutes>=0 && minutes <=9){
                    minutes = "0"+minutes;
                }
                if(seconds>=0 && seconds <=9){
                    seconds = "0"+seconds;
                }
                that.min = minutes;
                that.sec = seconds;
                --validTime;
                // sessionStorage.setItem('validTime',JSON.stringify(validTime));
            } else{
                clearInterval(timer);
                that.$alert(
                        '"订单已超时，请重新购买课程"',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: '确认',
                            showCancelButton: false,
                            type: "warning"
                        }
                    )
                .then(() => {
                    that.$router.push('/ClassList');
                    sessionStorage.removeItem('validTime')
                })

            }
                // 
            }, 1000)
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            that.$once("hook:beforeDestroy", () => {
                clearInterval(timer);
                // sessionStorage.removeItem('validTime');
            });

        },
        
        // countdown() {
        //     let that = this;
        //     var time = JSON.parse(sessionStorage.getItem('validTime'));
        //     console.log(time)
        //     var minute = parseInt(time / 60);
        //     var second = parseInt(time % 60);
        //     console.log(minute,second,"分钟")
        //     that.min = minute;
        //     that.sec = second;
        //     that.sec = JSON.parse(sessionStorage.getItem('validSec'));
            
        //     that.time = window.setInterval(function () {
        //         if(that.sec === 0 & that.min !== 0) {
        //             that.sec = 59
        //             that.min -=1
        //         } else if(that.min === 0 && that.sec ===0) {
        //             that.sec = 0
        //             console.log(that.sec,"分钟")
        //             clearInterval(that.time);
        //             console.log("倒计时结束");
        //             that.$alert(
        //                 '"订单已超时，请重新购买课程"',
        //                 {
        //                     distinguishCancelAndClose: true,
        //                     confirmButtonText: '确认',
        //                     showCancelButton: false,
        //                     type: "warning"
        //                 }
        //             )
        //         .then(() => {
        //             this.$router.push('/ClassList');
        //             sessionStorage.removeItem('validTime')
        //             sessionStorage.removeItem('validSec')
        //             sessionStorage.removeItem('validMin')
        //         })
        //         } else {
        //             that.sec -=1
        //         }
        //         sessionStorage.setItem('validMin',JSON.stringify(that.min));
        //         sessionStorage.setItem('validSec',JSON.stringify(that.sec));
        //     }, 1000)
            
        // },
        paySubmit(payType){
            if(payType == 0){
                this.payType = 0;
                this.axios({
                    method: 'POST',
                    url: 'api/v2/yun/order/createNative.html',
                    data: {
                        orderId: this.orderId,
                        payType: this.payType
                    }
                }).then((res) => {
                    QRCode.toDataURL(res.data.result.codeUrl)
                    .then(url => {
                        this.payImg = url;
                        this.loopOrderState()
                    })
                })
            } else {
                this.payType = 1;
                this.axios({
                    method: 'POST',
                    url: 'api/v2/yun/order/createNative.html',
                    data: {
                        orderId: this.orderId,
                        payType: this.payType
                    }
                }).then((res) => {
                    QRCode.toDataURL(res.data.result.codeUrl)
                    .then(url => {
                        this.payImg = url;
                        this.loopOrderState()
                    })
                })
            }
        },
        loopOrderState() {
            this.T = setInterval(()=>{
                if(this.payType == 0) {
                    this.axios({
                        method: 'POST',
                        url: 'api/v2/yun/order/check_order.html',
                        data: {
                            orderId: this.orderId,
                            payType: this.payType
                        }
                    }).then((res) => {
                        if(res.data.code == 200) {
                            window.clearInterval(this.T);
                            this.$router.push({
                                path: '/orderSuccess',
                                query: {
                                    orderId: this.orderId
                                }
                            });
                        }
                    })
                } else if(this.payType == 1) {
                    this.axios({
                        method: 'POST',
                        url: 'api/v2/yun/order/check_order.html',
                        data: {
                            orderId: this.orderId,
                            payType: this.payType
                        }
                    }).then((res) => {
                        if(res.data.code == 200) {
                            window.clearInterval(this.T);
                            this.$router.push({
                                path: '/orderSuccess',
                                query: {
                                    orderId: this.orderId
                                }
                            });
                        }
                    })
                }
            
        },3000);
        this.$once("hook:beforeDestroy", () => {
                clearInterval(this.T);
            });
        }
    }
}
</script>

<style lang="scss">
    .pay-box {
        padding-top: 90px;
        padding-bottom: 30px;
        width: 100%;
        min-width: 1200px;
        background: #eeeeee;
        .pay-content {
            margin-bottom: 30px;
            margin: 0 auto;
            width: 1200px;
            height: 833px;
            background: #FFFFFF;
            .cart-title {
                padding-left: 30px;
                padding-right: 30px;
                margin-bottom: 40px;
                display: flex;
                justify-content: space-between;
                height: 118px;
                border-bottom: 1px solid #EEEEEE;
                .title {
                    line-height: 118px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    color: #444444;
                }
                .warp-box {
                    padding-top: 30px;
                    ul {
                        display: flex;
                        li {
                            text-align: center;
                            .img {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                            .img2 {
                                margin-bottom: 10px;
                                width: 94px;
                                height: 36px;
                            }
                            .img3 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                            .img4 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                        }
                    }
                }
            }
        }
        .warp-content {
            .order-wrap{
                padding: 0px 50px;
                font-size:14px;
                margin-bottom:30px;
                border-bottom: 1px solid #EEEEEE;
                .item-order{
                .order-info{
                    box-sizing: border-box;
                    padding-bottom: 20px;
                    text-align: center;
                    h2{
                        font-size:24px;
                        color:#333333;
                        margin-bottom:20px;
                    }
                    p{
                        color:#666666;
                        span{
                            color:#FF6700;
                        }
                    }
                    .text-time {
                        margin-bottom: 12px;
                    }
                }
                .order-total{
                    &>p:first-child{
                    margin-bottom:30px;
                    }
                    span{
                    font-size:28px;
                    color:#FF6700;
                    }
                    .icon-down{
                    display:inline-block;
                    width:14px;
                    height:10px;
                    background: red;
                    margin-left:9px;
                    transition:all .5s;
                    cursor:pointer;
                    &.up{
                        transform: rotate(180deg);
                    }
                    }
                    .icon-up{
                    transform: rotate(180deg);
                    }
                }
                }
                .item-detail{
                border-top: 1px solid #D7D7D7;
                padding-top: 47px;
                padding-left: 130px;
                font-size: 14px;
                margin-top: 45px;
                .item{
                    margin-bottom:19px;
                    .detail-title{
                    float:left;
                    width:100px;
                    }
                    .detail-info{
                    display:inline-block;
                    img{
                        width: 30px;
                        vertical-align: middle;
                    }
                    }
                }
                }
            }
        }
        .item-pay{
            padding: 0px 50px 72px;
            background-color:#ffffff;
            color: #333333;
            h3{
                font-size: 20px;
                font-weight: 200;
                color: #333333;
            }
            .pay-way{
                margin-bottom: 57px;
                font-size:18px;
                text-align: center;
                .pay{
                    display:inline-block;
                    width: 158px;
                    height: 57px;
                    // background: #F9F9F9;
                    border: 1px solid #E5E5E5;
                    border-radius: 5px;
                    &:last-child{
                    margin-left:20px;
                    }
                    &.checked{
                    border: 4px solid #FA4B66;
                    }
                }
                .pay-ali{
                    background:url('../assets/img/pay/icon-ali.jpg') no-repeat center;
                    // background-size:103px 38px;
                    margin-top:19px;
                }
                .pay-wechat{
                    background:url('../assets/img/pay/icon-wechat.jpg') no-repeat center;
                    // background-size:103px 38px;
                }
            }
        }
        .pay-imgBox {
            text-align: center;
            .pay-Img {
                margin: 0 auto;
                margin-bottom: 30px;
                width: 210px;
                height: 210px;
                // background: blue;
                // border: 1px solid #E5E5E5;
            }
            .pay-text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #444444;
                .pay-color {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FF5A74;
                }
            }
        }
    }
</style>